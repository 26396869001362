import React from 'react';
import one from "../assets/img/one-1.webp";
import two from "../assets/img/two-2.webp";
import three from "../assets/img/three-3.webp";

const SolvedCard = ({ title, description, imageUrl, link,listItems,textp  }) => (
  <div className="solved-card">
    <div className="card-body">
      <h4 className="card-title">{title}</h4>
      <div className="card-text">
        <p>{description}</p>
        {listItems && (
          <ul>
            {listItems.map((item, index) => (
              <li key={index} style={{ fontWeight: 400 }} aria-level="1">
                <span className='markernew'>•</span> <span style={{ fontWeight: 400 }}>{item}</span>
              </li>
            ))}
          </ul>
        )}
        
      </div>
    </div>
    <div className="card-img">
      <img loading="lazy" width="100" height="100" src={imageUrl} className="img-responsive" alt="Icon" />
    </div>
    <div className="card-footer">
      <a href={link} className="btn card-btn ignore-external">
        {textp} >
      </a>
    </div>
  </div>
);

const SolvedSection = () => (
  <div className="container news">
    <h2 className="solved-section-title">Full Service Digital Marketing</h2>
    <p className="solved-section-subtitle">Flatart offers 360-degree digital marketing solutions to your needs with its expert team.</p>
    <div className="row sp_40 flex_row">
      {/* Solved Card 1 */}
      <div className="col-xs-12 col-md-6 solved-card-col solved-card-col1 teps">
        <SolvedCard
          title="It takes too much time to manage our digital campaigns"
          description="Are you tired of spending countless hours managing your digital campaigns, only to feel like you're not getting the results you desire? We, at Flatart, understand that digital marketing can be overwhelming and time-consuming, but the good news is that there's a solution: partnering with an expert agency!"  
          imageUrl={one} 
          textp="Discover Our Digital Ads Solutions"
          link="https://flatart.agency/digital-ads/"
          listItems={[
            'Time Efficiency',
            'Cutting-Edge Tools and Technology',
            'Proven Results with Monthly Reports'
          ]}
        />
      </div>
      {/* Solved Card 2 */}
      <div className="col-xs-12 col-md-6 solved-card-col solved-card-col2">
        <SolvedCard
          title="Our website does not get enough traffic"
          description="Are you frustrated with the lack of traffic to your website? You're not alone. In today's digital age, having a website is not enough. SEO is the key to unlocking its full potential. By partnering with Flatart, you'll not only boost your website's traffic but also enhance its online presence, credibility, and ultimately, your bottom line!"
          textp="Discover Our SEO Solutions"
          imageUrl={two} 
          link="https://flatart.agency/search-engine-optimization/"
          listItems={[
            'Strategies Specific to Your Brand and Industry',
            'Quick Adaptation to Algorithm Updates',
            'Accurate and High Quality Content Creation'
          ]}
        />
      </div>
      {/* Solved Card 3 */}
      <div className="col-xs-12 col-md-6 solved-card-col solved-card-col3 news">
        <SolvedCard
          title="We invest in digital marketing but are not seeing any results"
          description="Are you pouring resources into digital marketing efforts, only to find that the results are falling short of your expectations? Don't worry. Many companies face similar challenges in navigating the complex world of digital marketing. This is where a specialized digital marketing agency like Flatart, can be your game-changer!"
          textp="Discover Our Digital Consulting Solutions"
          imageUrl={three}
          link="https://flatart.agency/digital-marketing-consultancy/"
          listItems={[
            'Keeping Up with Trends',
            'Data-Driven Decisions',
            'Cost Efficiency'
          ]}
        />
      </div>
     
    
    </div>
  </div>
);

export default SolvedSection;
