import React, { Component } from "react";
import "../assets/scss/carousel.scss";

// Büyük boyutlu video
import team1MobileVideo from "../assets/img/main/Flatart_mobil_slider_tasarimi_1.mp4";

class Slider extends Component {
  constructor(props) {
    super();
    this.state = {
      currentIndex: 0
    };
  }

  componentDidMount() {
    // Videoyu önceden yükle
    const link = document.createElement('link');
    link.rel = 'preload';
    link.href = team1MobileVideo;
    link.as = 'video';
    document.head.appendChild(link);

    // Kullanıcı bir yere tıkladığında videoyu oynat
    document.addEventListener("click", this.handleVideoPlay);
  }

  componentWillUnmount() {
    // Olay dinleyicisini kaldır
    document.removeEventListener("click", this.handleVideoPlay);
  }

  handleVideoPlay = () => {
    const video = document.querySelector("video");
    if (video) {
      video.play().catch((error) => {
        console.log("Video otomatik oynatılamıyor:", error);
      });
    }
  };

  render() {
    const { currentIndex } = this.state;

    return (
      <div className="slider">
        <div className="slider-video-container mobile" >
          <video
            className={`slider-video desktop ${currentIndex === 0 ? "active" : ""}`}
            src={team1MobileVideo}
            width={400}
            height={600}
            autoPlay
            loop
            muted
            playsInline
            controls={false}
            style={{ width: "100%", height: "auto", objectFit: "cover" }}
          >
            Tarayıcınız video etiketini desteklemiyor.
          </video>
        </div>
      </div>
    );
  }
}

export default Slider;
