import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MobilCarousel from "../components/mobil-carousel"
import Carousel from "../components/carousel"
import Companys from "../components/companys"
import Checkup from "../components/checkup"
import Testimonial from "../components/testimonial"
import whoWeAreImg from "../assets/img/murathan-yildirim-flatart.jpg";
import whoWeAreImg1 from "../assets/img/biz-kimiz.png";
import analyseImg from "../assets/img/main/veri-analizi.jpg";
import acquireImg from "../assets/img/main/yuksek-kaliteli-trafik.jpg";
import convertImg from "../assets/img/main/kazandiran-trafik.jpg";
import costFriendly from "../assets/img/cost-friendly.png";
import resultBased from "../assets/img/result-based-agency.png";
import shopifyEcommerce from "../assets/img/shopify-ecommerce-expert.png";
import transparencyIsKey from "../assets/img/transparency-is-key.png";
import squad from "../assets/img/squad.png";
import SolvedSection from "../components/solved"


class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: false,
      value: ''
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    e.preventDefault();

    this.setState({ value: e.target.value });
  }
  showModal(e) {

    this.setState({ display: !this.state.display });
    document.querySelector('.check-modal').classList.add('active-modal');

    document.querySelector('#site').innerHTML = this.state.value;
  }

  closeModal() {
    this.setState({ display: !this.state.display })
    document.querySelector('.check-modal').classList.remove('active-modal');
  }

  render() {
    return (

      <Layout>
        <SEO title="Homepage" />
        <div className="caro">
        <div className="caps">
        <div className="buttonnewss">
          <a href="/start-project/" target="_blank">
            <button className="red-button newsss"> &nbsp; Start Project &nbsp; </button>
          </a>
          <button onClick={(e) => this.showModal()} className="red-button newsss">Free Report</button>
          </div>
        </div>
        <div className="desktop-component">
          <Carousel /> {/* Masaüstü bileşeni burada göster */}
        </div>
        <div className="mobile-component">
          <MobilCarousel /> {/* Mobil bileşeni burada göster */}
        </div>
      </div>
        <div className="hero">
    
          <h2>We believe in data.<span role="img">✌️</span></h2>
          
        </div>
        <div className="clients container">
          <div className="clients-content ">
            <h2>Here are some success stories of the brands we work with.</h2>
            <p>Check out the following figures to learn how we strengthen our brands.</p>
          </div>
          <div className="clients-customers">
            <div className="clients-customers__sefa-stone">
              <p>e-canta.com.tr</p>
              <span>+%26</span>
              <p className="mh-42">ROPTIMIZATION OF ADVERTISING COST</p>
              <Link to="/success-stories/ecanta/">Find out more.</Link>
            </div>
            <div className="clients-customers__towelnrobe">
              <p>matbuu.com</p>
              <span>+%150</span>
              <p className="mh-42">INCREASE IN ORGANIC TRAFFIC</p>
              <Link to="/success-stories/matbuu/">Find out more.</Link>
            </div>
            <div className="clients-customers__robesnmore">
              <p>pakmaya.com.tr</p>
              <span>10x</span>
              <p className="mh-42">INCREASE IN ORGANIC TRAFFIC</p>
              <Link to="/success-stories/pakmaya/">Find out more.</Link>
            </div>
          </div>
          <Link to="/brands/" className="red-button">Check out our portfolio</Link>
        </div>

        <div className="referances container py60">
          <h2 style={{ marginBottom:40}}>Brands that we are working together.</h2>
          {/* <img alt="alt text" src={refImg} /> */}
          <div style={{marginBottom:30}}>
          <Companys />
          </div>
        </div>
        <SolvedSection /> 

        <div className="who-we-are container" style={{marginTop: -40}}>
          
          <div className="who-we-are__content col-md-4">
            <h2>Who are we?</h2>
            <p>We are working <br /> to make a greater <br /> impact than anticipated.</p>
            <p style={{width:286}}>Productive, constantly improving, and specialised… Our team loves to make a difference! We provide all encompassing digital marketing solutions to you.</p>
            <Link to="/about/">Meet the team.</Link>
          </div>
          <div className="col-md-8">
          <img  class="tgy" alt="Flatart Ekip" src={whoWeAreImg1} />
          </div>
        </div>

        <div className="services container py60">
          <div className="services-help">
            <span>Analysis <svg className="arrow-right" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 563 1061.4" space="preserve">
            <polygon points="123.1,0 0,100.7 356.3,536.2 0.3,958.9 121.9,1061.4 563,537.6 " />
          </svg> Acquisition <svg className="arrow-right" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 563 1061.4" space="preserve">
            <polygon points="123.1,0 0,100.7 356.3,536.2 0.3,958.9 121.9,1061.4 563,537.6 " />
          </svg> Conversion</span>
            <h2>The services we provide <br /> for your needs.</h2>
            <p>We invest in your physical world by strengthening your hand in the digital world.</p>
          </div>
          <div className="services-list">
            <div className="services-list__item">
              <div className="col-md-6 col-sm-12">
                <img alt="alt text" src={analyseImg} />
                </div>
              <div className="col-md-6 col-sm-12 services-list__item-content">
                <span>— Analysis</span>
                <h2>Data Analysis.<br />An exclusive plan for<br/> your brand.</h2>
                <p>We examine your target audience and combine this analysis with our methods of associating to strategise your digital actions.</p>
                <Link to="/analysis/">Check out the analysis process.</Link>
              </div>
            </div>
            <div className="services-list__item acquire-reverse" style={{ padding: '0px 85px' }}>
              <div className="col-md-6 col-sm-12 services-list__item-content" >
                <span>— Acquisition</span>
                <h2 style={{ width: '85%' }}>High quality traffic.</h2>
                <p>We structure digital plans that enable you to meet with your potential customers and have high-quality traffic.</p>
                <Link to="/acquisition/">Check out the traffic acquisition process.</Link>
              </div>
              <div className="col-md-6 col-sm-12 pt15">
                <img alt="alt text" class="convert-img" src={acquireImg} />
              </div>
            </div>

            <div className="services-list__item">
              <div className="col-md-6 col-sm-12"><img alt="alt text" class="convert-img" src={convertImg} /></div>
              <div className="col-md-6 col-sm-12 services-list__item-content">
                <span>— Conversion</span>
                <h2>Achieved targets.<br />A traffic full with gain.</h2>
                <p>We ensure that the targeted conversion of users who interact with your brand is achieved.</p>
                <Link to="/conversion/">Check out the transformation process.</Link>
              </div>
            </div>
          </div>

        </div>

        <div className="choose-us pb60 container neden-biz">
          <div className="choose-us_box">
            <img alt="alt text" className="design-squad" src={squad} />
            <h2>Why you should work with us?</h2>
            <p className="choose-us_desc">We are building the future of your brand with data analytics,<br />and we showcase our experience in digital marketing for you.</p>
            <div className="choose-us_box__features">

              <div className="choose-feature">
                <img alt="alt text" src={shopifyEcommerce}></img>
                <div className="choose-feature_content">
                  <h3>Shopify E-commerce Specialist.</h3>
                  <p>We can reserve a place for you on the<br/>world's most preferred e-commerce platform.</p>
                </div>
              </div>

              <div className="choose-feature">
                <img alt="alt text" src={costFriendly}></img>
                <div className="choose-feature_content">
                  <h3>Budget-Friendly.</h3>
                  <p>We give you the most effective results at the best prices.</p>
                </div>
              </div>

              <div className="choose-feature">
                <img alt="alt text" src={resultBased}></img>
                <div className="choose-feature_content">
                  <h3>A Result-Oriented Agency.</h3>
                  <p>We openly share the results of all our work with you.</p>
                </div>
              </div>

              <div className="choose-feature">
                <img alt="alt text" src={transparencyIsKey}></img>
                <div className="choose-feature_content">
                  <h3>Transparent Business Processes.</h3>
                  <p>We inform you at every stage of our digital activities.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Checkup />

        <Testimonial />

      </Layout >
    )
  }
}
export default Home
