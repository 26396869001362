import React, { Component } from "react";
import "../assets/scss/carousel.scss";

// Büyük boyutlu video
import team1Desktop from "../assets/img/main/flatart.guncel.mp4";

class Slider extends Component {
  constructor(props) {
    super();

    this.state = {
      currentIndex: 0
    };
  }

  render() {
    const { currentIndex } = this.state;

    return (
      <div className="slider">
        <div className="slider-video-container">
          <video
            className={`slider-video desktop ${currentIndex === 0 ? "active" : ""}`}
            src={team1Desktop}
            width={1920}
            height={600}
            autoPlay
            loop
            muted
            playsInline
            controls={false}
            loading="lazy"
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    );
  }
}

export default Slider;
